import { memo } from 'react';

import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import Widget, { AddWidgetButtonType } from 'lux/models/employerConstructor/widget.types';
import translation from 'src/components/translation';

const TrlKeys = {
    description: {
        [Widget.Text]: 'employer.constructor.widgets.nolimit.TEXT',
        [Widget.Picture]: 'employer.constructor.widgets.nolimit.PICTURE',
        [Widget.Address]: 'employer.constructor.widgets.nolimit.ADDRESS',
        [Widget.Gallery]: 'employer.constructor.widgets.nolimit.GALLERY',
        [Widget.Video]: 'employer.constructor.widgets.nolimit.VIDEO',
        [Widget.Separator]: 'employer.constructor.widgets.nolimit.SEPARATOR',
    },
    name: {
        [Widget.Text]: 'employer.constructor.widget.type.text',
        [Widget.Picture]: 'employer.constructor.widget.type.picture',
        [Widget.Address]: 'employer.constructor.widget.type.address',
        [Widget.Gallery]: 'employer.constructor.widget.type.gallery',
        [Widget.Video]: 'employer.constructor.widget.type.video',
        [Widget.Separator]: 'employer.constructor.widget.type.separator',
    },
    limit: 'employer.constructor.widgets.limit',
    nolimit: 'employer.constructor.widgets.nolimit',
    blocksOne: 'employer.constructor.widgets.nolimit.blocks.one',
    blocksSome: 'employer.constructor.widgets.nolimit.blocks.some',
    blocksMany: 'employer.constructor.widgets.nolimit.blocks.many',
};

interface AddWidgetButtonTipProps {
    type: AddWidgetButtonType;
    limit?: number;
    count: number;
}

const AddWidgetButtonTip: TranslatedComponent<AddWidgetButtonTipProps> = ({ type, limit, count, trls }) => {
    if (!limit) {
        return <>{trls[TrlKeys.description[type]]}</>;
    }

    if (count >= limit) {
        return formatToReactComponent(trls[TrlKeys.limit], {
            '{0}': trls[TrlKeys.name[type]],
        });
    }

    return (
        <>
            {trls[TrlKeys.description[type]]}{' '}
            {formatToReactComponent(trls[TrlKeys.nolimit], {
                '{0}': limit - count,
                '{1}': (
                    <Conversion
                        one={trls[TrlKeys.blocksOne]}
                        some={trls[TrlKeys.blocksSome]}
                        many={trls[TrlKeys.blocksMany]}
                        value={limit - count}
                        hasValue={false}
                    />
                ),
                '{2}': trls[TrlKeys.name[type]],
            })}
        </>
    );
};

export default memo(translation(AddWidgetButtonTip));
