import { KeyboardEventHandler, FocusEventHandler, useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import vacancySearchLineFormFieldTouch, {
    HhtmSource,
} from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/vacancy_search_line_form_field_touch';
import { useBreakpoint, SearchInput } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useSetStateWithCallback from 'lux/hooks/useSetStateWithCallback';
import { updateEmployerVacancySearchCriteria } from 'lux/models/employerVacancySearch';
import { CriteriaKey } from 'lux/models/search/searchCriteria.types';
import { SupernovaSearchName } from 'lux/models/supernovaSearchName';
import { useSelector } from 'lux/modules/useSelector';
import { INPUT_ID } from 'src/components/A11y/A11yConstants';
import SuggestWithDefaultErrorPlaceholder from 'src/components/SuggestWithDefaultErrorPlaceholder';
import { SuggestItem } from 'src/components/SupernovaSearch/MagritteSearchSuggest/types';
import useProvider from 'src/components/SupernovaSearch/MagritteSearchSuggest/useProvider';
import translation from 'src/components/translation';

const TrlKeys = {
    submit: 'supernova.search.submit',
    tooLongQuery: 'query.length.moreThanMax',
    placeholder: 'supernova.search.placeholder.searchVacancy',
    submitVacancies: 'supernovaSearch.bottomSheet.title.vacancies',
};

const MAX_QUERY_LENGTH = 3000;

const SearchSuggest: TranslatedComponent<{ onFormSubmit: () => void }> = ({ trls, onFormSubmit }) => {
    const dispatch = useDispatch();
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource as HhtmSource);
    const defaultSearchValue = useSelector(({ employerVacancySearch }) => employerVacancySearch.criteria?.text || '');
    const [queryText, setQueryText] = useSetStateWithCallback<string>(defaultSearchValue);
    const inputRef = useRef<HTMLElement>(null);
    const dataProvider = useProvider(SupernovaSearchName.Vacancies);
    const { isMobile } = useBreakpoint();

    const inputLabel = trls[TrlKeys.submitVacancies];

    const updateText = useCallback(
        (text: string, submit?: () => void) => {
            setQueryText(text, submit);
            dispatch(updateEmployerVacancySearchCriteria({ [CriteriaKey.Text]: text }));
        },
        [dispatch, setQueryText]
    );

    const handleItemSelect = useCallback(
        (_: string, item?: SuggestItem): boolean => {
            if (item) {
                updateText(item.text, onFormSubmit);
            }
            return true;
        },
        [onFormSubmit, updateText]
    );

    const handleMobileKeyboardSubmit: KeyboardEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            // Проверяем на isMobile, чтобы в десктопной версии не вызывать триггер формы дважды.
            // Там срабатывает нативный сабмит внутри формы.
            if (event.key === 'Enter' && isMobile) {
                onFormSubmit();
            }
        },
        [isMobile, onFormSubmit]
    );

    const onFocusInput: FocusEventHandler<HTMLInputElement> = useCallback(() => {
        vacancySearchLineFormFieldTouch({ hhtmSource });
    }, [hhtmSource]);

    const inputProps = useMemo(() => {
        return {
            type: undefined,
            onFocus: onFocusInput,
            id: INPUT_ID,
            name: CriteriaKey.Text,
            value: queryText,
            placeholder: trls[TrlKeys.placeholder],
            onKeyDown: handleMobileKeyboardSubmit,
            ref: inputRef,
            onChange: updateText,
            clearable: true,
            maxLength: MAX_QUERY_LENGTH,
            autoComplete: 'off',
            'aria-label': inputLabel,
            'data-qa': 'employer-vacancy-search-input',
        };
    }, [onFocusInput, queryText, trls, handleMobileKeyboardSubmit, updateText, inputLabel]);

    return (
        <SuggestWithDefaultErrorPlaceholder
            inputValue={queryText}
            onSelectValidator={handleItemSelect}
            dataProvider={dataProvider}
            input={{
                component: SearchInput,
                props: inputProps,
            }}
            navigationBarProps={{
                title: inputLabel,
            }}
        />
    );
};

export default translation(SearchSuggest);
