import { FC } from 'react';

import { useSelector } from 'lux/modules/useSelector';
import SearchType from 'src/components/NovaFilters/SearchType';
import ScrollToTop from 'src/components/ScrollToTop';

const EmployerVacancySearchScrollToTop: FC = () => {
    const totalSnippets = useSelector((state) => state.employerVacancySearch.vacancies.length);
    const isMobileFiltersShown = useSelector((state) => state.searchPreference.isShown);

    return (
        <ScrollToTop
            analyticsParams={{ searchType: SearchType.EmployerVacancy, totalSnippets }}
            forceHidden={isMobileFiltersShown}
        />
    );
};

export default EmployerVacancySearchScrollToTop;
