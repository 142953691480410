import { FC, memo, PropsWithChildren } from 'react';

import SkeletonLine from 'src/components/Skeleton/SkeletonLine';
import SkeletonRepeat from 'src/components/Skeleton/SkeletonRepeat';
import SkeletonStripe from 'src/components/Skeleton/SkeletonStripe';

import styles from './skeleton.less';

const Skeleton: FC<PropsWithChildren> = ({ children }) => <div className={styles.container}>{children}</div>;

export default memo(Skeleton);
export { SkeletonLine, SkeletonRepeat, SkeletonStripe };
