import { Component, createRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import EDITOR_COMMON_SETTINGS, {
    DISABLED_PLUGINS,
    EDITOR_TOOLBAR_CONSTRUCTOR,
    EDITOR_TOOLBAR_DEFAULT,
    EDITOR_TOOLBAR_SIMPLE,
    PLUGINS,
    PLUGINS_ACTION,
    PropTypeDynamicPlugins,
} from 'Modules/CKEditor/common-settings';

import getEditorPromise from 'src/components/Editor/ckeditorLoader';
import Loader from 'src/components/Editor/loader';

const extractPluginsData = (plugins, toolbar) =>
    plugins.reduce(
        (memo, { name, action, externalInitializer, props }) => {
            const [currentExtra, currentRemove, currentExternalInit, currentProps] = memo;

            if (PLUGINS_ACTION.ADD === action) {
                currentExtra.push(name);
            } else if (PLUGINS_ACTION.REMOVE === action) {
                currentRemove.push(name);
            }

            if (externalInitializer) {
                currentExternalInit.push(externalInitializer);
            }

            return [
                currentExtra,
                currentRemove,
                currentExternalInit,
                {
                    ...currentProps,
                    ...props,
                },
            ];
        },
        [[], [...DISABLED_PLUGINS, toolbar === EDITOR_TOOLBAR_DEFAULT ? PLUGINS.INDENT_BLOCK : null], [], {}]
    );

class EditorComponent extends Component {
    static propTypes = {
        'data-qa': PropTypes.string,
        uiColor: PropTypes.string,
        height: PropTypes.number,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onEditorReady: PropTypes.func,
        toolbar: PropTypes.oneOf([EDITOR_TOOLBAR_SIMPLE, EDITOR_TOOLBAR_CONSTRUCTOR, EDITOR_TOOLBAR_DEFAULT]),
        value: PropTypes.string,
        invalid: PropTypes.bool,
        delayBlur: PropTypes.bool,
        strictHeight: PropTypes.bool,
        formatTags: PropTypes.string,
        plugins: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypeDynamicPlugins,
                action: PropTypes.oneOf(Object.values(PLUGINS_ACTION)),
                externalInitializer: PropTypes.func,
                props: PropTypes.object,
            })
        ),
        overrideLang: PropTypes.object,
        editorClassName: PropTypes.string,
        spacesCSS: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOf(['top', 'contents', 'bottom']),
                styles: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string,
                        value: PropTypes.string,
                    })
                ),
            })
        ),
    };

    static defaultProps = {
        onBlur: () => {},
        onChange: () => {},
        onFocus: () => {},
        onEditorReady: () => {},
        delayBlur: true,
        strictHeight: true,
        plugins: [],
        overrideLang: {},
        spacesCSS: [],
    };

    state = {
        focused: false,
        loading: true,
    };

    editorPlaceholderElement = createRef();

    getContentCss = () => {
        return [`${window.globalVars.staticHost}/${window.globalVars.cssMaping['other-ckeditor'].path}`];
    };

    onBlur = (e) => {
        this.setState({ focused: false });

        // force change value if actual editor value != value stored in props (HH-102093)
        const value = this.ckeditor.editable().getData() || '';
        if (this.props.value !== value) {
            this.onChange();
        }

        this.props.onBlur(e);
    };

    lastValue = '';
    onChange = () => {
        const data = this.ckeditor.editable().getData();
        this.lastValue = data;
        this.props.onChange(data);
    };

    onFocus = (e) => {
        this.setState({ focused: true });
        this.props.onFocus(e);
    };

    componentDidMount() {
        const {
            uiColor,
            height,
            toolbar,
            value,
            formatTags,
            delayBlur,
            strictHeight,
            plugins,
            onEditorReady,
            overrideLang,
        } = this.props;
        const [extraPlugins, removePlugins, pluginsExternalInit, pluginProps] = extractPluginsData(plugins, toolbar);

        const initInternalEditor = () => {
            if (!this.editorPlaceholderElement.current) {
                return;
            }

            const editorHeight = height || this.editorPlaceholderElement.current.clientHeight;
            const CKEDITOR = window.CKEDITOR;
            if (!delayBlur) {
                CKEDITOR.focusManager._.blurDelay = 0;
            }

            CKEDITOR.disableAutoInline = true;

            pluginsExternalInit.forEach((initPlugin) => {
                initPlugin(CKEDITOR);
            });

            this.ckeditor = CKEDITOR.replace(this.editorPlaceholderElement.current, {
                ...EDITOR_COMMON_SETTINGS,
                uiColor,
                contentsCss: this.getContentCss(),
                height: editorHeight,
                toolbar,
                plugins: CKEDITOR.config.plugins
                    .split(',')
                    .filter((name) => !removePlugins.includes(name))
                    .join(','),
                extraPlugins: extraPlugins.length ? extraPlugins.join(',') : null,
                // eslint-disable-next-line camelcase
                ...(formatTags ? { format_tags: formatTags } : {}),
                ...pluginProps,
            });

            this.ckeditor.on('langLoaded', () => {
                for (const plugin in overrideLang) {
                    this.ckeditor.lang[plugin] = { ...this.ckeditor.lang[plugin], ...overrideLang[plugin] };
                }
            });

            this.ckeditor.on('instanceReady', () => {
                this.ckeditor.setData(value || '', {
                    callback: () => {
                        this.ckeditor.resetUndo();
                        this.ckeditor.on('focus', this.onFocus);
                        this.ckeditor.on('blur', this.onBlur);
                        this.applySpacesCSS();
                        this.createModeListener();
                        this.attachChangeListeners();
                        strictHeight && this.ckeditor.resize('100%', editorHeight);
                        this.setState({ loading: false });
                    },
                });
                onEditorReady(this.ckeditor);
            });
        };

        getEditorPromise().then(initInternalEditor)?.catch?.(console.error);
    }

    applySpacesCSS() {
        const { spacesCSS } = this.props;

        for (const space of spacesCSS) {
            for (const style of space.styles) {
                this.ckeditor.ui.space(space.id).setStyle(style.name, style.value);
            }
        }
    }

    createModeListener() {
        const editor = this.ckeditor;
        const onChange = this.onChange;
        this.modeListener = function () {
            if (this.mode === 'source') {
                editor.editable().attachListener(editor.editable(), 'input', onChange);
            }
        };
    }

    detachChangeListeners() {
        this.ckeditor.removeListener('mode', this.modeListener);
        this.ckeditor.removeListener('change', this.onChange);
    }

    attachChangeListeners = () => {
        this.ckeditor.on('mode', this.modeListener);
        this.ckeditor.on('change', this.onChange);
    };

    componentDidUpdate() {
        if (!this.state.loading) {
            const oldValue = this.lastValue;
            const value = this.props.value || '';
            if (value !== oldValue) {
                this.lastValue = value;
                // CKEditor is post-formatting data, so it is likely to update form and save draft right after it
                this.detachChangeListeners();
                this.ckeditor.setData(value, { callback: this.attachChangeListeners });
            }
        }
    }

    componentWillUnmount() {
        if (this.ckeditor) {
            this.ckeditor.destroy();
            this.ckeditor.removeAllListeners();
        }
    }

    render() {
        const { loading, focused } = this.state;
        const { invalid, editorClassName } = this.props;

        return (
            <div
                className={classnames('editor-container', {
                    'editor-container_focused': focused,
                    'editor-container_invalid': invalid,
                    [editorClassName]: !!editorClassName,
                })}
                data-qa={this.props['data-qa']}
            >
                <Loader loading={loading} />
                <div className={'editor'} ref={this.editorPlaceholderElement} />
            </div>
        );
    }
}

export default EditorComponent;
