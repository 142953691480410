import React, { Fragment, FC } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import { H3Section } from 'bloko/blocks/header';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text, { TextSize, TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import urlParser from 'bloko/common/urlParser';

import { useSelector } from 'lux/modules/useSelector';
import Compensation from 'src/components/Compensation';
import OrganizationForm from 'src/components/Employer/OrganizationForm';
import HumanDate, { ShowYear } from 'src/components/HumanDate';
import InsiderInterviewLink from 'src/components/InsiderInterviewLink';

import { Vacancy } from 'src/components/Employer/VacanciesGroupsByRegion/VacancyGroupsTypes';

interface VacancyItemProps extends Vacancy {
    organizationFormId: number | null;
}
const VacancyItem: FC<VacancyItemProps> = ({
    name,
    company,
    publicationTime,
    area,
    links,
    compensation,
    address,
    insider,
    organizationFormId,
}) => {
    const companyLink = urlParser(`/employer/${company.id}`);
    if (company.department?.code) {
        companyLink.params = {
            dpt: company.department.code,
        };
    }
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);
    const linkWithFrom = `${links.desktop}${hhtmSource ? `?from=${hhtmSource}` : ''}`;

    return (
        <div className="vacancy-list-item">
            <div className="vacancy-list-item__block vacancy-list-item__block_description">
                <H3Section Element="span" lite>
                    <span className="serp-item__title">
                        <span className="g-user-content">
                            <BlokoLink Element={Link} to={linkWithFrom} data-qa="vacancy-serp__vacancy-title">
                                {name}
                            </BlokoLink>
                        </span>
                    </span>
                </H3Section>
                <VSpacing base={4} />
                <div>
                    <BlokoLink Element={Link} to={companyLink.href} kind={LinkKind.Secondary}>
                        <OrganizationForm organizationFormId={organizationFormId} />
                        {company.visibleName}
                    </BlokoLink>
                    ,{' '}
                    <Text Element="span" size={TextSize.Small} importance={TextImportance.Secondary}>
                        <HumanDate date={publicationTime['@timestamp'] * 1000} showYear={ShowYear.Never} />
                    </Text>
                    <InsiderInterviewLink insider={insider} companyId={company.id} className="b-vacancy-list-insider" />
                </div>
            </div>
            <div className="vacancy-list-item__block vacancy-list-item__block_compensation">
                <div className="b-vacancy-list-salary">
                    <Compensation {...compensation} />
                </div>
            </div>
            <div className="vacancy-list-item__block vacancy-list-item__block_address">
                <Text Element="div" size={TextSize.Small}>
                    {area.name}
                    {!!address?.metroStations?.metro?.length && (
                        <Fragment>
                            {', '}
                            <br />
                            {address.metroStations.metro.map((metroStation, index, stations) => (
                                <p key={metroStation.id} className="metro-station">
                                    <span className="metro-point" style={{ color: metroStation.line.color }} />
                                    {metroStation.name}
                                    {index !== stations.length - 1 && ', '}
                                </p>
                            ))}
                        </Fragment>
                    )}
                </Text>
            </div>
        </div>
    );
};

export default VacancyItem;
