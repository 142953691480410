import { connect, DefaultRootState } from 'react-redux';

import { H2 } from 'bloko/blocks/header';
import Text, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import RegionVacancies from 'src/components/Employer/VacanciesGroupsByRegion/RegionVacancies';
import { Region, RegionTypes } from 'src/components/Employer/VacanciesGroupsByRegion/VacancyGroupsTypes';

const TrlKeys = {
    title: 'employerview.title',
    currentRegion: 'employerview.current-region',
    otherRegions: 'employerview.other-regions',
    empty: 'employer.vacancies.none',
};

interface VacanciesGroups {
    currentRegion: Region;
    otherRegions?: Region;
    employerId: number | null;
    vacanciesCount?: number;
    hasChameleon?: boolean;
}

const VacanciesGroups: TranslatedComponent<VacanciesGroups> = ({
    currentRegion,
    otherRegions,
    employerId,
    vacanciesCount,
    trls,
    hasChameleon = false,
}) => {
    return !vacanciesCount || !employerId ? (
        <div className="employer-vacancies-empty" data-qa="vacancies-empty-message">
            <Text size={TextSize.Large}>{trls[TrlKeys.empty]}</Text>
        </div>
    ) : (
        <>
            {!hasChameleon && (
                <div className="employer-vacancies-header">
                    <H2>{trls[TrlKeys.title]}</H2>
                </div>
            )}

            <RegionVacancies
                region={currentRegion}
                title={`${trls[TrlKeys.currentRegion]} ${currentRegion?.name || ''}`}
                employerId={employerId}
                regionType={RegionTypes.Current}
                expanded
            />
            {!!otherRegions && (
                <RegionVacancies
                    region={otherRegions}
                    title={trls[TrlKeys.otherRegions]}
                    employerId={employerId}
                    regionType={RegionTypes.Other}
                    expanded={false}
                />
            )}
        </>
    );
};

export default connect(({ vacanciesGroupsByRegion, employerInfo }: DefaultRootState) => ({
    vacanciesCount: vacanciesGroupsByRegion?.count,
    currentRegion: vacanciesGroupsByRegion.currentRegion,
    otherRegions: vacanciesGroupsByRegion.otherRegions,
    employerId: employerInfo.id,
}))(translation(VacanciesGroups));
