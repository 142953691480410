import { FC, PropsWithChildren } from 'react';

import { usePageTabsContext, EmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';

interface EmployerPageTabContentProps {
    tab: EmployerPageTab;
}

const EmployerPageTabContent: FC<PropsWithChildren<EmployerPageTabContentProps>> = ({ tab, children }) => {
    const { showContentForTab } = usePageTabsContext();

    if (!showContentForTab(tab)) {
        return null;
    }

    return <>{children}</>;
};

export default EmployerPageTabContent;
