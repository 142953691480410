import { setLogoStatus, Status } from 'lux/models/employer/employerLogo';
import fetcher from 'lux/modules/fetcher';
import defaultError from 'src/components/Notifications/DefaultError';

import uploadLogoCheckStatus from 'src/components/Employer/Sidebar/uploadLogoCheckStatus';

export default (payload, addNotification, employerLogoEditSettings) => (dispatch) => {
    dispatch(setLogoStatus(Status.Fetching));
    return fetcher.postFormData('/employer/edit/logo', { file: payload }).then(
        () => {
            dispatch(uploadLogoCheckStatus(addNotification, employerLogoEditSettings));
        },
        () => {
            dispatch(setLogoStatus(Status.Fail));
            addNotification(defaultError);
        }
    );
};
