import { useCallback } from 'react';
import { useRouteMatch } from 'react-router';

import { EmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';

const DEFAULT_TAB = EmployerPageTab.Description;

const tabToRouteMap = {
    [EmployerPageTab.Vacancies]: '/vacancies',
    [EmployerPageTab.Description]: '/',
};
const getTabRoute = (tab: EmployerPageTab) => tabToRouteMap[tab] || tabToRouteMap[DEFAULT_TAB];
export const getRouteTab = (route: string | undefined): EmployerPageTab =>
    (Object.entries(tabToRouteMap).find(([, tabRoute]) => tabRoute === route)?.[0] as EmployerPageTab) || DEFAULT_TAB;

interface UseTabUrlResult {
    (tab: EmployerPageTab): string;
}

const useTabUrl = (): UseTabUrlResult => {
    const match = useRouteMatch<{ 0: string; 1: string }>();
    const baseUrl = `/employer/${match.params[0]}`;

    return useCallback((tab: EmployerPageTab) => `${baseUrl}${getTabRoute(tab)}`, [baseUrl]);
};

export default useTabUrl;
