import { FC, ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import vacancySearchTabButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/employer_page_vacancy_search/vacancy_search_tab_button_click';
import vacancySearchTabElementShown from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/employer_page_vacancy_search/vacancy_search_tab_element_shown';
import { Tabs, Tab, Button, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useSelector } from 'lux/modules/useSelector';
import translation from 'src/components/translation';

import { usePageTabsContext, EmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';
import useTabUrl from 'src/components/Employer/PageTabs/useTabUrl';

const TrlKeys = {
    [EmployerPageTab.Description]: 'employer.view.tab.description',
    [EmployerPageTab.Vacancies]: 'employer.view.tab.vacancies',
    activeVacancies: 'employer.view.tab.vacancies.active',
};

const TabLink: FC<ComponentPropsWithoutRef<'a'>> = (props) => (
    <a
        {...props}
        onClick={(e) => {
            e.preventDefault();
            props.onClick?.(e);
        }}
    />
);

const EmployerPageTabs: TranslatedComponent = ({ trls }) => {
    const vacancyCount = useSelector(
        (state) => state.vacanciesGroupsByRegion?.count ?? state.activeEmployerVacancyCount
    );
    const employerId = useSelector((state) => state.employerInfo.id);
    const { currentTab, setCurrentTab, isMobileView, isEmployerTabsExp } = usePageTabsContext();

    const makeTabUrl = useTabUrl();

    if (!isEmployerTabsExp || !employerId) {
        return null;
    }

    const analyticsParams = { vacancyCount, employerId };

    if (isMobileView) {
        // На XS активатор выводим только на главной
        if (currentTab !== EmployerPageTab.Description) {
            return null;
        }
        // Вместо вкладок кнопка "Активные вакансии"
        return (
            <>
                <VSpacing default={28} />
                <ElementShownAnchor fn={vacancySearchTabElementShown} {...analyticsParams}>
                    <Button
                        Element={TabLink}
                        href={makeTabUrl(EmployerPageTab.Vacancies)}
                        onClick={() => {
                            setCurrentTab(EmployerPageTab.Vacancies);
                            vacancySearchTabButtonClick(analyticsParams);
                        }}
                        mode="primary"
                        style="accent"
                        postfix={`${vacancyCount || ''}`}
                        stretched
                        data-qa="employer-page-tabs-xs-go-active-vacancies"
                    >
                        <div style={{ textAlign: 'left' }}>{trls[TrlKeys.activeVacancies]}</div>
                    </Button>
                </ElementShownAnchor>
            </>
        );
    }
    return (
        <>
            <VSpacing default={24} />
            <ElementShownAnchor fn={vacancySearchTabElementShown} {...analyticsParams}>
                <Tabs activeItemId={currentTab} onChange={setCurrentTab}>
                    {Object.values(EmployerPageTab).map((tab) => (
                        <Tab
                            Element={TabLink}
                            href={makeTabUrl(tab)}
                            onClick={() =>
                                tab === EmployerPageTab.Vacancies && vacancySearchTabButtonClick(analyticsParams)
                            }
                            id={tab}
                            key={tab}
                            postfix={tab === EmployerPageTab.Vacancies ? `${vacancyCount || ''}` : undefined}
                            data-qa={classNames(`employer-page-tabs-desktop-go-${tab}`, {
                                'employer-page-tabs-desktop-active': tab === currentTab,
                            })}
                        >
                            {trls[TrlKeys[tab]]}
                        </Tab>
                    ))}
                </Tabs>
            </ElementShownAnchor>
        </>
    );
};

export default translation(EmployerPageTabs);
