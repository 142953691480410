import { FC, PropsWithChildren } from 'react';

import { Button } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { sendAnalyticsWantWorkHere } from 'Modules/ApplicantAnalytics';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';
import translation from 'src/components/translation';

const sendAnalytics = (userType: UserType, employerId: number) => () => {
    if (userType === UserType.Anonymous || userType === UserType.Applicant) {
        sendAnalyticsWantWorkHere(`${employerId}`);
    }
};

export const WantWorkHereLink: FC<PropsWithChildren> = ({ children, ...props }) => {
    const userType = useSelector((state) => state.userType);
    const employerId = useSelector((state) => state.employerInfo.id);
    const hasWantWarkService = useSelector((state) =>
        state.applicantPaymentServices.includes('RESUME_TARGET_EMPLOYER')
    );
    const isZpEmployer = useSelector((state) => state.employerInfo.isZpEmployer);

    if (isZpEmployer || !hasWantWarkService || !employerId) {
        return null;
    }

    return (
        <Link
            to={`/applicant/wantwork/payment?employerId=${employerId}&from=employer_sidebar&hhtmFromLabel=employer_sidebar`}
            data-qa="resumeservice-button__targetemployer"
            onMouseUp={sendAnalytics(userType, employerId)}
            {...props}
        >
            {children}
        </Link>
    );
};

const TrlKeys = {
    employerViewNeedWork: 'employer.page.wantWork',
};

interface WantWorkHereProps {
    stretched?: boolean;
}

const WantWorkHere: TranslatedComponent<WantWorkHereProps> = ({ trls, stretched }) => (
    <Button size="small" style="neutral" mode="secondary" stretched={stretched} Element={WantWorkHereLink}>
        {trls[TrlKeys.employerViewNeedWork]}
    </Button>
);

export default translation(WantWorkHere);
